import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import Buttons from './Buttons';
import donorImage from '../../images/donor-default-image.png';
import Moment from 'react-moment';
import ReactMarkdown from 'react-markdown';
import Linkify from 'react-linkify';
import Parser from 'html-react-parser';
import MediaGrid from './MediaGrid';
import CONSTANTS from '../../services/constants';
import bussinessDefaultLogo from '../../images/business-default-logo.png';
import GeolocationView from '../common/GeolocationView';

class Manual extends Component {
    MAX_POST_SIZE = 200;
    logo;
    date;
    kleventContent;
    finalContent;
    cuttedString;

    constructor(props) {
        super(props);
        this.state = {
            seeMore: false,
            hasSeeMore: false,
            showMap: false
        };
    };

    componentWillMount() {
        const {klevent} = this.props;

        this.logo = this.getLogo();
        this.date = klevent.createdDate
        this.kleventContent = this.replacePlaceholders();
        this.finalContent = this.kleventContent.content;
        if ((klevent.type === CONSTANTS.KLEVENT.TYPE.MANUAL_UPDATE_CAMPAIGN || klevent.type === CONSTANTS.KLEVENT.TYPE.CHARITY_PROJECT_UPDATE) && !this.state.initSeeMore) {
            this.finalContent = this.initSeeMore(this.kleventContent.content);
        }
    }

    getLogo() {
        const {klevent} = this.props;
        let logoPlaceholder = klevent.placeholders.filter(function (item) {
            return item.name === "logo";
        });

        let img;
        if (logoPlaceholder.length && logoPlaceholder[0].resources && logoPlaceholder[0].resources.length) {
            img = logoPlaceholder[0].resources[0].value;
        }
        if (!img) {
            img = donorImage;
        }
        return img;
    }

    toggleSeeMore() {
        const currentState = this.state.seeMore;
        this.setState({seeMore: !currentState});
        if (!currentState) {
            this.finalContent = this.kleventContent.content;
        } else {
            this.finalContent = this.cuttedString;
        }
    }

    replacePlaceholders() {
        const {klevent} = this.props;
        let kleventContent = {
            "title": klevent.title,
            "content": klevent.content
        };
        for (var index = 0; index < klevent.placeholders.length; index++) {
            if (klevent.placeholders[index].resources[0] !== undefined) {
                kleventContent.content = kleventContent.content.replace("%%" + klevent.placeholders[index].name + "%%", klevent.placeholders[index].resources[0].value);
                kleventContent.title = kleventContent.title.replace("%%" + klevent.placeholders[index].name + "%%", klevent.placeholders[index].resources[0].value);
            }
        }
        return kleventContent;
    }

    initSeeMore(orgContent) {

        this.setState({initSeeMore: true});

        let dummyDomElement = document.createElement('div');
        dummyDomElement.innerHTML = orgContent;
        let content = dummyDomElement.textContent;
        if (content && content.length > this.MAX_POST_SIZE) {

            let splittedContent = content.substring(0, this.MAX_POST_SIZE).split(' ');
            let lastFullWord = "";
            let interator = splittedContent.length - 1;
            while (interator > 0) {
                interator--;
                if (splittedContent[interator].length > 3) {
                    lastFullWord = splittedContent[interator];
                    break;
                }
            }

            let wordMatchCount = splittedContent.filter(function (item) {
                return item === lastFullWord;
            }).length;
            let lastFullWordPosition = orgContent.split(lastFullWord, wordMatchCount).join(lastFullWord).length;
            let cuttedStringBeggining = orgContent.substring(0, lastFullWordPosition + lastFullWord.length);
            let cuttedStringEnding = orgContent.substring(lastFullWordPosition + lastFullWord.length);

            let regExpOpeningHrefTag = new RegExp("<a", "gi");
            let regExpClosingHrefTag = new RegExp("</a>", "gi");
            let hrefTagsCount = (cuttedStringBeggining.match(regExpOpeningHrefTag) || []).length - (cuttedStringBeggining.match(regExpClosingHrefTag) || []).length;
            if (hrefTagsCount) {
                cuttedStringBeggining += cuttedStringEnding.substring(0, cuttedStringEnding.indexOf("</a>")) + "</a>";
            }


            this.setState({hasSeeMore: true});

            if (!this.state.seeMore) {
                orgContent = cuttedStringBeggining;
                this.cuttedString = cuttedStringBeggining;
            }
        }
        return orgContent;
    }

    toggleMapVisibility = () => {
        this.setState({showMap: !this.state.showMap});
    }

    render() {

        const {klevent, wallId, business} = this.props;
        let images = klevent.placeholders.filter(function (item) {
            return item.name === CONSTANTS.KLEVENT.MEDIA.IMAGES;
        });
        let video = klevent.placeholders.filter(function (item) {
            return item.name === CONSTANTS.KLEVENT.MEDIA.VIDEO;
        });

        let imageUrl = bussinessDefaultLogo;
        if (images.length && images[0].resources && images[0].resources[0] && images[0].resources[0].value) {
            imageUrl = images[0].resources[0].imagePaths.original;
        } else if (business.logoPhoto) {
            imageUrl = business.logoPhoto;
        }
        let params = {
            "pageUri": business.uri,
            "wallId": wallId,
            "kleventId": klevent.id,
            "imageUrl": imageUrl,
            "companyName": business.companyName,
            "content": this.finalContent
        };

        let location = klevent.geolocation && this.state.showMap 
            ? <div className="klw-profile--kindwall--map">
                <GeolocationView geolocation={klevent.geolocation} /></div> 
            : null;
        let locationButton = <button onClick={this.toggleMapVisibility}>{this.state.showMap ? "Hide Map" : "Show map" }</button>

        return (
            <div>
                <div className="klw-profile--kindwall-item-wrapper klw-profile--kindwall-item-manual-klevent">

                    <div className="klw-profile--kindwall--meta">
                        <img className="klw-profile--kindwall-user-image" src={this.logo} width="50" height="50"
                             alt={this.logo}/>
                        <ReactMarkdown source={this.kleventContent.title}/>
                        <div className="klw-profile--kindwall-post-date">
                            <Moment format="d MMMM YYYY, H:mm">
                                {this.date}
                            </Moment>
                        </div>
                    </div>
                    <div className="klw-profile--kindwall--content">

                        {klevent.type === CONSTANTS.KLEVENT.TYPE.USER_ADDED_CONTRIBUTION &&
                        <ReactMarkdown source={this.finalContent} escapeHtml={false}/>
                        }

                        {klevent.type !== CONSTANTS.KLEVENT.TYPE.USER_ADDED_CONTRIBUTION &&
                        <Linkify>
                            {Parser(this.finalContent)}
                        </Linkify>
                        }

                        {this.state.hasSeeMore &&
                        <span className="link clickable"
                              onClick={this.toggleSeeMore.bind(this)}>{!this.state.seeMore ? "...See more" : " See less"}</span>
                        }

                    </div>
                    <div>
                        {images.length > 0 &&
                        <MediaGrid media={images}/>
                        }
                    </div>
                    <div>
                        {video.length > 0 &&
                        <MediaGrid media={video}/>
                        }
                    </div>
                    {location}
                    <hr/>
                    <Buttons shareParams={params}/>
                    {klevent.geolocation ? locationButton : null}
                </div>
            </div>
        );
    }
}

Manual.propTypes = {
    klevent: PropTypes.object.isRequired,
    wallId: PropTypes.number.isRequired,
    business: PropTypes.object.isRequired,
};

export default Manual;
