import User from './User';
import Placeholder from './Placeholder';

class Klevent {
    content;
    createdDate;
    id;
    klEventCategory;
    owner;
    owners;
    placeholders;
    publicId;
    status;
    title;
    type;
    updateDate;
    geolocation;

    populate(params) {
        if (!params.owner instanceof User) {
            let owner = new User();
            owner.populate(params.owner);
            this.owner = owner;
        } else {
            this.owner = params.owner;
        }

        if (params.owners && params.owners.length) {
            let owners = [];
            for (let i = 0; i < params.owners.length; i++) {
                let owner = params.owners[i];
                if (!params.owners[i] instanceof User) {
                    owner = new User();
                    owner.populate(params.owners[i]);
                }
                owners.push(owner);
            }
            this.owners = owners;
        } else {
            this.owners = params.owners;
        }

        if (params.placeholders && params.placeholders.length) {
            let placeholders = [];
            for (let i = 0; i < params.placeholders.length; i++) {
                let placeholder = params.placeholders[i];
                if (!params.placeholders[i] instanceof Placeholder) {
                    placeholder = new Placeholder();
                    placeholder.populate(params.placeholders[i]);
                }
                placeholders.push(placeholder);
            }
            this.placeholders = placeholders;
        } else {
            this.placeholders = params.placeholders;
        }


        this.content = params.content;
        this.createdDate = params.createdDate;
        this.id = params.id;
        this.klEventCategory = params.klEventCategory;
        this.placeholders = params.placeholders;
        this.publicId = params.publicId;
        this.status = params.status;
        this.title = params.title;
        this.type = params.type;
        this.updateDate = params.updateDate;
        this.geolocation = params.geolocation;
    }
}

export default Klevent;
