class ThemeHelper {

    themeStyleTagId = "custom-theme-applied";

    set(color) {

        if(!color) {
            return;
        }

        this.clearUpExistingThemeStyle();

        let cssCode = (
            // menu tabs
            "ul.klw-profile--kindwall-filter li span.active { color: #{color} } " +
            // business statistics
            "table.klw-profile--stats-table tr td { color: #{color} } " +
            // automatic klevent icon background
            "div.klw-profile--kindwall-icon { background-color: #{color} } " +
            // see more link for automatic klevents
            "div.klw-profile--kindwall-item-auto-klevent strong:last-of-type a span { color: #{color} } " +
            // content links in manual klevents
            "div.klw-profile--kindwall-item-manual-klevent div.klw-profile--kindwall--content strong a { color: #{color} }" +
            // see more link
            "div.klw-profile--kindwall--content span.clickable { color: #{color} }" +
            // links that are not bolded
            "div.klw-profile--kindwall--content a:not(:last-child) { color: #{color} }"
        ).replace(/{color}/g, color);

        let head  = document.head || document.getElementsByTagName('head')[0];
        let style = document.createElement('style');
        style.type = 'text/css';
        style.id = this.themeStyleTagId;
        if (style.styleSheet){
          style.styleSheet.cssText = cssCode;
        } else {
          style.appendChild(document.createTextNode(cssCode));
        }
        head.appendChild(style);

    }

    clearUpExistingThemeStyle() {
        // clear up existing style tag.
        let existingElement = document.getElementById(this.themeStyleTagId);
        if(existingElement) {
            existingElement.parentNode.removeChild(existingElement);
        }
    }

}

export default ThemeHelper;
