import axios from 'axios';
import ErrorHandler from './errorHandler.service';
import CONFIG from '../config';


class RequesterPrivates extends ErrorHandler {
    static scopes;
    http;
    config;
    requestScope;
    parentScope;

    constructor(requestScope, parentScope) {
        super();
        this.initProperties();
        this.config = RequesterPrivates.scopes[requestScope];
        this.requestScope = requestScope;
        this.parentScope = parentScope;
        return this;
    }

    static getConfig(configName) {
        let that;
        try {
            that = this;
            return new Promise(function (resolve, reject) {
                axios.get(configName)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error);
                    });
            });
        } catch (e) {
            that.catchError(e);
        }
    }

    initProperties() {
        RequesterPrivates.scopes = {
            "social": {
                "baseUrl": CONFIG.SOCIAL.HOST,
                "headers": {
                    "Authorization": CONFIG.getAuthorizationToken(),
                    "Content-Type": "application/json; charset=utf-8"
                }
            }
        };
    }

    setConfigOptions(options) {
        this.initProperties();
        this.config = RequesterPrivates.scopes[this.requestScope];

        let config = {
            baseURL: this.config.baseUrl,
            headers: this.config.headers
        };
        this.http = axios.create(config);

        if (typeof options !== "undefined") {
            for (let key in options) {
                if (options.hasOwnProperty(key)) {
                    if (typeof options[key] === "object") {
                        if (typeof config[key] === "undefined") {
                            config[key] = {};
                        }
                        for (let innerKey in options[key]) {
                            if (options[key].hasOwnProperty(innerKey)) {
                                config[key][innerKey] = options[key][innerKey];
                            }
                        }
                    } else {
                        config[key] = options[key];
                    }
                }
            }
        }
        if (typeof options !== "undefined" || !this.http) {
            this.http = axios.create(config);
        }
    }

    post(address, params, options) {
        let that;
        try {
            that = this;
            this.setConfigOptions(options);
            this.http.post(address, params)
                .then(function (response) {
                })
                .catch(function (error, code) {
                    that.serverError(error, code);
                });
        } catch (e) {
            that.catchError(e);
        }
    }

    get(address, params, options) {
        let that;
        try {
            that = this;
            this.setConfigOptions(options);
            return new Promise(function (resolve, reject) {
                that.http.get(address, params)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error, code) {
                        reject(that.serverError(error, code));
                    });
            });
        } catch (e) {
            that.catchError(e);
        }
    }

    getPublicToken() {
        let that;
        try {
            that = this;
            let options = {
                "headers": {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                "baseURL": CONFIG.AUTH.HOST
            };
            this.setConfigOptions(options);
            return new Promise(function (resolve, reject) {
                that.http.post("/oauth/token", "grant_type=client_credentials")
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                        reject(error);
                    });
            });
        } catch (e) {
            that.catchError(e);
        }
    }

    put(address, params, options) {
        let that;
        try {
            that = this;
            this.setConfigOptions(options);
            return new Promise(function (resolve, reject) {
                that.http.put(address, params)
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (error, code) {
                        that.serverError(error, code);
                    });
            });
        } catch (e) {
            that.catchError(e);
        }
    }

    patch(address, params, options) {
        let that;
        try {
            that = this;
            this.setConfigOptions(options);
            this.http.patch(address, params)
                .then(function (response) {
                })
                .catch(function (error, code) {
                    that.serverError(error, code);
                });
        } catch (e) {
            that.catchError(e);
        }
    }

    delete(address, params, options) {
        let that;
        try {
            that = this;
            this.setConfigOptions(options);
            this.http.delete(address, params)
                .then(function (response) {
                })
                .catch(function (error, code) {
                    that.serverError(error, code);
                });
        } catch (e) {
            that.catchError(e);
        }
    }
}

export default RequesterPrivates;
