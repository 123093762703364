import * as actions from '../../actions';
import Campaign from '../../models/Campaign';

export default (state = [], action) => {
    switch (action.type) {
        case actions.CAMPAINS_LIST[actions.FAILURE]:
            console.log("CAMPAINS_LIST ERROR");
            break;
        case actions.CAMPAINS_LIST[actions.SUCCESS]:
            if (typeof state.campaigns === "undefined") {
                let groupCampaigns = action.response.body.groupCampaigns.map(
                    current => {
                        let campaign = new Campaign();
                        campaign.populate(current);
                        return campaign;
                });
                let pageCampaigns = action.response.body.pageCampaigns.map(
                    current => {
                        let campaign = new Campaign();
                        campaign.populate(current);
                        return campaign;
                });
                let subCampaigns = action.response.body.subCampaigns.map(
                    current => {
                        let campaign = new Campaign();
                        campaign.populate(current);
                        return campaign;
                });

                return Object.assign({}, state, {
                    "groupCampaigns": groupCampaigns,
                    "pageCampaigns": pageCampaigns,
                    "subCampaigns": subCampaigns
                });
            }
            return state;
        default:
            return state;
    }
};
