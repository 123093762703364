class Project {
    createdDate;
    crmProjectId;
    previousCrmProjectId;
    projectId;
    title;
    updatedDate;

    populate(params) {
        this.createdDate = params.createdDate;
        this.crmProjectId = params.crmProjectId;
        this.previousCrmProjectId = params.previousCrmProjectId;
        this.projectId = params.projectId;
        this.title = params.title;
        this.updatedDate = params.updatedDate;
    }
}

export default Project;
