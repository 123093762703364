import React, {Component} from 'react';

class Loader extends Component {

    componentWillMount() {
        this.clearOverlay();
    }

    componentDidMount() {
        if (this.props.parentId) {
            let el = document.getElementById(this.props.parentId);
            if (el) {
                el.classList.add("overlay");
            }
        } else {
            document.body.classList.add("overlay");
        }
    }

    componentWillUnmount() {
        this.clearOverlay();
    }

    clearOverlay() {
        var elements = document.getElementsByClassName("overlay");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove("overlay");
        }
    }

    render() {
        return (
            <div>
                <div className='spinner-container'>
                    <div className='kindlink-spiner-grad'>
                        <div className='kindlink-spinner-svg'></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loader;