import User from './User';
import Business from './Business';
import Charity from './Charity';
import Wall from './Wall';

class Campaign {
    business;
    campaignName;
    category;
    charities;
    cost;
    coverPhoto;
    coverPhotoName;
    createdDate;
    currency;
    description;
    employee;
    endDate;
    events;
    featured;
    imagePaths;
    owner;
    pageId;
    status;
    story;
    targetValue;
    type;
    updatedDate;
    uri;
    uuid;
    visibility;
    walls;
    focus;
    fundraising;
    manageParticipation;
    raisedAmount;

    populate(params) {

        if (!params.business instanceof Business) {
            let business = new Business();
            business.populate(params.business);
            this.business = business;
        } else {
            this.business = params.business;
        }

        if (params.charities && params.charities.length) {
            let charities = [];
            for (let i = 0; i < params.charities.length; i++) {
                let charity = params.charities[i];
                if (!params.charities[i] instanceof Charity) {
                    charity = new Charity();
                    charity.populate(params.charities[i]);
                }
                charities.push(charity);
            }
            this.charities = charities;
        } else {
            this.charities = params.charities;
        }

        if (!params.owner instanceof User) {
            let owner = new User();
            owner.populate(params.owner);
            this.owner = owner;
        } else {
            this.owner = params.owner;
        }

        if (params.walls && params.walls.length) {
            let walls = [];
            for (let i = 0; i < params.walls.length; i++) {
                let wall = params.walls[i];
                if (!params.walls[i] instanceof Wall) {
                    wall = new Wall();
                    wall.populate(params.walls[i]);
                }
                walls.push(wall);
            }
            this.walls = walls;
        } else {
            this.walls = params.walls;
        }

        this.campaignName = params.campaignName;
        this.category = params.category;
        this.cost = params.cost;
        this.coverPhoto = params.coverPhoto;
        this.coverPhotoName = params.coverPhotoName;
        this.createdDate = params.createdDate;
        this.currency = params.currency;
        this.description = params.description;
        this.employee = params.employee;
        this.endDate = params.endDate;
        this.events = params.events;
        this.featured = params.featured;
        this.imagePaths = params.imagePaths;
        this.pageId = params.pageId;
        this.status = params.status;
        this.story = params.story;
        this.targetValue = params.targetValue;
        this.type = params.type;
        this.updatedDate = params.updatedDate;
        this.uri = params.uri;
        this.uuid = params.uuid;
        this.visibility = params.visibility;
        this.focus = params.focus;
        this.fundraising = params.fundraising;
        this.manageParticipation = params.manageParticipation;
        this.raisedAmount = params.raisedAmount;
    }
}

export default Campaign;