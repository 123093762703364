import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import Walls from './Walls';
import Statistics from './Statistics';
import Info from './Info';
import Share from './Share';
import Loader from '../common/Loader';
import ThemeHelper from '../../helpers/theme-helper';

class Profile extends Component {

    componentWillMount() {
        this.props.actions.loadProfilePage(this.props.match.params.businessUri);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState == null && this.props.profile) {
            let themeHelper = new ThemeHelper();
            themeHelper.set(this.props.profile.color);
        }
    }

    render() {
        const {profile, widget, errors} = this.props;
        let widgetInfo = widget;

        //uncomment on development mode
        // widgetInfo = {
        //     companyInfo: true,
        //     companyStats: true,
        //     companyWalls: true
        // };

        if (typeof profile !== "undefined") {
            if (typeof widgetInfo === "undefined") {
                //show some kind of error page
                return (
                    <div>
                        Widget info is not loaded
                    </div>
                )
            } else {
                let widgetTypeClasses = {
                    "001": "klw-profile--kindwall",
                    "010": "klw-profile--stats",
                    "011": "klw-profile--stats-kindwall",
                    "100": "klw-profile--company",
                    "101": "klw-profile--company-kindwall",
                    "110": "klw-profile--company-stats",
                    "111": "klw-profile--full"
                };
                let widgetTypeClass = widgetTypeClasses["" + (widgetInfo.companyInfo * 1) + (widgetInfo.companyStats * 1) + (widgetInfo.companyWalls * 1)];
                let allClasses = "container klw-profile " + widgetTypeClass;
                return (
                    <div className={allClasses}>
                        {(widgetInfo.companyInfo || widgetInfo.companyStats || widgetInfo.companyWalls) &&
                        <Share business={profile}/>
                        }
                        {widgetInfo.companyInfo &&
                        <Info business={profile}/>
                        }
                        {widgetInfo.companyStats &&
                        <Statistics business={profile}/>
                        }
                        {widgetInfo.companyWalls &&
                        <Walls widget={widgetInfo} business={profile}/>
                        }
                    </div>
                )
            }
        } else if (errors) {
            return (
                <div>
                    Your KindLink profile is private. Please make your business profile public to view this widget.
                </div>
            );
        } else {
            return (
                <Loader/>
            );
        }


    }
}

Profile.propTypes = {
    actions: PropTypes.object.isRequired
};

Profile.defaultProps = {};

function mapStateToProps(state, ownProps) {
    return {
        widget: state.initialLoad.widget,
        profile: state.profile.info,
        errors: state.profile.errors,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
