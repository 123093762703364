import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import './css/skeleton.css';
import {Provider} from 'react-redux';
import configureStore from './store/configure-store';
import rootSaga from './sagas';

const store = configureStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <div>
            <Routes/>
        </div>
    </Provider>,
    document.getElementById('root')
);


