import Project from './Project';

class Charity {
    charityId;
    charityPublicId;
    email;
    id;
    logo;
    logoUrl;
    name;
    position;
    project;
    supportedCurrencies;

    populate(params) {

        if (!params.project instanceof Project) {
            let project = new Project();
            project.populate(params.project);
            this.project = project;
        } else {
            this.project = params.project;
        }

        this.charityId = params.charityId;
        this.charityPublicId = params.charityPublicId;
        this.email = params.email;
        this.id = params.id;
        this.logo = params.logo;
        this.logoUrl = params.logoUrl;
        this.name = params.name;
        this.position = params.position;
        this.supportedCurrencies = params.supportedCurrencies;
    }
}

export default Charity;
