import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import CampaignsListItem from './CampaignsListItem';
import {getCampaignListUrl} from "../../services/utils";
import Loader from '../common/Loader';

class CampaignsList extends Component {

    DEFAULT_ITEMS_COUNT = 6;

    componentWillMount() {
        this.props.actions.loadCampaignsListPage(this.props.match.params.businessUri);
    }

    render() {
        const {pageCampaigns, groupCampaigns, subCampaigns, widget} = this.props;

        if (typeof pageCampaigns === "undefined" || !pageCampaigns) {
            return (
                <Loader/>
            );
        }

        let campaigns;
        if(widget && widget.extendedList){
            campaigns = [].concat(groupCampaigns,pageCampaigns,subCampaigns);
        } else {
            campaigns = pageCampaigns;
        }
        let businessUri = this.props.match.params.businessUri;
        let sortedCampaigns = campaigns.sort(function (a, b) {
            return a.status > b.status;
        });

        let itemsCount = this.DEFAULT_ITEMS_COUNT;
        if(widget && widget.maxItems && widget.maxItems > 0){
            itemsCount = widget.maxItems;
        }

        let showCampaigns = [];
        for (let i = 0; i < sortedCampaigns.length; i++) {
            if (i === itemsCount) {
                break;
            }
            showCampaigns.push(sortedCampaigns[i]);
        }

        return (
            <div className="container klw--campains-list">
                <div className="row">
                    {showCampaigns.map((campaign) =>
                        <CampaignsListItem key={campaign.uri} campaign={campaign}/>
                    )}
                </div>
                {campaigns.length > this.ITEMS_COUNT &&
                <div className="klw-all-link">
                    <a href={getCampaignListUrl(businessUri)}>View all {campaigns.length} campaings
                        by {campaigns[0].business.companyName}</a>
                </div>
                }

            </div>
        );
    }
}

CampaignsList.propTypes = {
    pageCampaigns: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired
};

CampaignsList.defaultProps = {
    pageCampaigns: [],
    subCampaigns: [],
    groupCampaigns: []
};

function mapStateToProps(state, ownProps) {
    return {
        pageCampaigns: state.campaignsList.pageCampaigns,
        subCampaigns: state.campaignsList.subCampaigns,
        groupCampaigns: state.campaignsList.groupCampaigns,
        widget: state.initialLoad.widget
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsList);
