import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import Moment from 'react-moment';
import ReactMarkdown from 'react-markdown';
import CONSTANTS from '../../services/constants';

import donorImage from '../../images/donor-default-image.png';
import iconCampaignTargetComplete from '../../images/icons/icon-campaign-target-complete.png';
import iconCorporateJoined from '../../images/icons/icon-corporate-joined.png';
import iconOfficialSupporter from '../../images/icons/icon-official-supporter.png';
import iconStartCampaign from '../../images/icons/icon-start-campaign.png';
import iconCampaignEnd from '../../images/icons/icon-campaign-end.png';

class Automatic extends Component {
    logo;
    date;

    componentWillMount() {
        const {klevent} = this.props;

        this.logo = this.getLogo();
        this.date = klevent.createdDate;
    }

    getLogo() {
        const {klevent} = this.props;
        let logoPlaceholder = klevent.placeholders.filter(function (item) {
            return item.name === "logo";
        });

        let img;
        if (logoPlaceholder.length && logoPlaceholder[0].resources && logoPlaceholder[0].resources.length) {
            img = logoPlaceholder[0].resources[0].value;
        }
        if (!img) {
            img = donorImage;
        }
        return img;
    }

    replacePlaceholders(klevent) {
        let kleventContent = {
            "title": klevent.title,
            "content": klevent.content
        };
        for (var index = 0; index < klevent.placeholders.length; index++) {
            if (klevent.placeholders[index].resources[0] !== undefined) {
                if (kleventContent.content) {
                    kleventContent.content = kleventContent.content.replace("%%" + klevent.placeholders[index].name + "%%", klevent.placeholders[index].resources[0].value);
                }
                if (kleventContent.title) {
                    kleventContent.title = kleventContent.title.replace("%%" + klevent.placeholders[index].name + "%%", klevent.placeholders[index].resources[0].value);
                }
            }
        }
        return kleventContent;
    }

    getIcon(klevent) {
        let types = CONSTANTS.KLEVENT.TYPE;
        switch (klevent.type) {
            case types.USER_ADDED_CONTRIBUTION:
                return iconCampaignEnd;
            case types.CAMPAIGN_END:
                return iconCampaignEnd;
            case types.CAMPAIGN_TARGET_COMPLETE:
                return iconCampaignTargetComplete;
            case types.CORPORATE_JOINED:
                return iconCorporateJoined;
            case types.NEW_USER_SIGNUP:
                return iconOfficialSupporter;
            case types.CAMPAIGN_START:
                return iconStartCampaign;

            default:
                return iconCampaignEnd;
        }
    }

    render() {
        const {klevent} = this.props;
        let kleventContent = this.replacePlaceholders(klevent);
        return (
            <div>
                <div className="klw-profile--kindwall-item-wrapper klw-profile--kindwall-item-auto-klevent">
                    <div className="klw-profile--kindwall-icon">
                        <div style={{"background": 'url(' + this.getIcon(klevent) + ')'}}>
                        </div>
                    </div>
                    <div className="klw-profile--kindwall--meta">
                        <img className="klw-profile--kindwall-user-image" src={this.logo} width="50" height="50"
                             alt={this.logo}/>
                        <div className="klw-profile--kindwall-post-date">
                            <Moment format="d MMMM YYYY, H:mm">
                                {this.date}
                            </Moment>
                        </div>
                        <ReactMarkdown className="klw-profile--kindwall-post-title" source={kleventContent.title}
                                       escapeHtml={false}/>

                    </div>
                </div>
            </div>
        );
    }
}

Automatic.propTypes = {
    klevent: PropTypes.object.isRequired
};

export default Automatic;