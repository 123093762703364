import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import CONSTANTS from '../../services/constants';
import ReactPlayer from 'react-player'

class MediaGrid extends Component {
    mediaType;
    resources = [];
    grid = []

    componentWillMount() {
        const {media} = this.props;
        this.resources = media[0].resources;
        this.mediaType = media[0].name;
        if (this.mediaType === CONSTANTS.KLEVENT.MEDIA.IMAGES) {
            this.grid = this.createGrid(this.resources);
        }
    }

    createGrid(resources) {

        const {widget} = this.props;
        let width = widget ? widget.containerWidth : window.innerWidth;
        width -= 100; //padings
        let sizes = {
            "smallestSize": Math.floor(width / 4) + "x" + Math.floor(width / 4),
            "smallSize": Math.floor(width / 3) + "x" + Math.floor(width / 3),
            "middleSize": Math.floor(width / 2) + "x" + Math.floor(width / 2),
            "bigSize": Math.floor(width) + "x" + Math.floor(width * 0.7),
        };

        let resourcesSizes = [
            [sizes.bigSize], //if there is only one resource in post
            [sizes.middleSize, sizes.middleSize], //if there is two resources in post
            [sizes.bigSize, sizes.middleSize, sizes.middleSize], // if there is three resources in post
            [sizes.smallestSize, sizes.smallestSize, sizes.smallestSize, sizes.smallestSize], // if there is four resources in post
            [sizes.middleSize, sizes.middleSize, sizes.smallSize, sizes.smallSize, sizes.smallSize]
        ];
        let resourcesArray = [];
        let size = resources.length - 1;
        if (size > 4) {
            size = 4;
        }
        for (var index = 0; index < resources.length; index++) {
            if (index > 4) break;
            if (!resources[index].imagePaths) continue;
            let thumbSize = resourcesSizes[size][index];
            let image = resources[index].imagePaths.thumbs["100x100"].replace("100x100", thumbSize);
            resourcesArray.push(image);
        }
        return resourcesArray;
    }

    renderImages() {
        return (
            <div className="klw-profile--kindwall--image-gallery">
                {this.grid.map((media, index) =>
                    <img key={index} src={media} alt={media}/>
                )}
            </div>
        );
    }

    renderVideo() {
        return (
            <div className="klw-profile--kindwall--image-gallery">
                <ReactPlayer url={this.resources[0].value} playing={false} controls={true} width="100%" height="auto"/>
            </div>
        );
    }

    render() {
        if (this.mediaType === CONSTANTS.KLEVENT.MEDIA.IMAGES) {
            return this.renderImages();
        } else {
            return this.renderVideo();
        }


    }
}

MediaGrid.propTypes = {
    media: PropTypes.array.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        widget: state.initialLoad.widget,
        media: ownProps.media

    };
}

export default connect(mapStateToProps)(MediaGrid);