import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import CONSTANTS from '../../services/constants';
import Manual from './Manual';
import Automatic from './Automatic';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';

class Klevent extends Component {
    render() {
        const {klevent, wallId, business} = this.props;
        if (klevent.klEventCategory === CONSTANTS.KLEVENT.CATEGORY.MANUAL) {
            return (
                <Manual klevent={klevent} wallId={wallId} business={business}/>
            );
        } else {
            return (
                <Automatic klevent={klevent}/>
            )
        }
    }
}

Klevent.propTypes = {
    klevent: PropTypes.object.isRequired,
    wallId: PropTypes.number.isRequired
};


function mapStateToProps(state, ownProps) {
    return {
        klevent: ownProps.klevent,
        wallId: ownProps.wallId,
        business: state.profile.info
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Klevent);