class Resource {
    createdDate;
    id;
    imagePaths;
    mimeType;
    value;

    populate(params) {
        this.createdDate = params.createdDate;
        this.id = params.id;
        this.imagePaths = params.imagePaths;
        this.mimeType = params.mimeType;
        this.value = params.value;
    }
}

export default Resource;
