import Requester from './requester.service';

// api services
const addresses = {
    "loadCampaignsList": "/campaigns/business/{businessUri}",
    "loadCampaignView": "/campaigns/uri/{campaignUri}"
};
export const loadCampaignsList = function (businessUri) {
    let req = new Requester();
    let link = addresses.loadCampaignsList.replace("{businessUri}", businessUri);
    return req.get(link).then(function (response) {
        response = response.data;
        return {response};
    }, function (error) {
        console.error("CAMPAIGNS LIST REDUCER FAILURE");
        console.error(error);

        return {error};
    });
};

export const loadCampaignView = function (campaignUri) {
    let req = new Requester();
    let link = addresses.loadCampaignView.replace("{campaignUri}", campaignUri);
    return req.get(link).then(function (response) {
        response = response.data;
        return {response};
    }, function (error) {
        console.error("CAMPAIGN VIEW REDUCER FAILURE");
        console.error(error);
        return {error};
    });
};
