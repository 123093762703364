import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import SharingUtils from '../../services/sharing-utils';
import CONSTANTS from '../../services/constants';

class Share extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
        this.shareUtils = new SharingUtils(CONSTANTS.SHARING.CONTEXTS.PROFILE);
    };

    toggleShareMenu() {
        const currentState = this.state.active;
        this.setState({active: !currentState});
    };

    getShareLink(network) {
        const {business} = this.props;
        let params = {
            "pageUri": business.uri,
            "imageUrl": business.logoPhoto,
            "companyName": business.companyName
        };
        return this.shareUtils.getLink(network, params);
    };

    openLink(link) {
        window.open(link);
    };

    doCopy() {
        let link = this.getShareLink(CONSTANTS.SHARING.NETWORKS.LINK);
        this.shareUtils.copyToClipboard(link);
    };

    render() {
        const {business} = this.props;
        let facebook = this.getShareLink(CONSTANTS.SHARING.NETWORKS.FACEBOOK);
        let twitter = this.getShareLink(CONSTANTS.SHARING.NETWORKS.TWITTER);
        let linkedin = this.getShareLink(CONSTANTS.SHARING.NETWORKS.LINKEDIN);
        let email = this.getShareLink(CONSTANTS.SHARING.NETWORKS.EMAIL);

        let params = {
            "pageUri": business.uri,
            "imageUrl": business.logoPhoto,
            "companyName": business.companyName
        };
        let options = {
            "selector": "profile-yammer"
        };
        this.shareUtils.initCustomShares(CONSTANTS.SHARING.NETWORKS.YAMMER, params, options);

        return (
            <div className={"dropdown--button--share " + (this.state.active ? 'show' : '')}
                 onClick={this.toggleShareMenu.bind(this)}>
                <button className="button button--share">Share</button>

                <div className="dropdown-menu nowrap">
                    <span className="dropdown-item link-icon-facebook" onClick={() => this.openLink(facebook)}></span>
                    <span className="dropdown-item link-icon-twitter" onClick={() => this.openLink(twitter)}></span>
                    <span className="dropdown-item link-icon-linkedin" onClick={() => this.openLink(linkedin)}></span>
                    <span className="dropdown-item link-icon-yammer profile-yammer"></span>
                    <span className="dropdown-item link-icon-email" onClick={() => this.openLink(email)}></span>
                    <span className="dropdown-item link-icon-copy" onClick={() => this.doCopy()}></span>
                </div>
            </div>
        );
    };
}

Share.propTypes = {
    business: PropTypes.object.isRequired
};

export default Share;