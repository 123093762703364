import RequesterPrivates from './requester.private.service';

class Requester {

    //multyton
    static instances;

    constructor(scope) {
        if (typeof Requester.instances === "undefined") {
            Requester.instances = [];
        }
        if (typeof scope === "undefined" || typeof RequesterPrivates.scopes[scope] === "undefined") {
            scope = "social";
        }
        if (typeof Requester.instances[scope] !== "undefined" && Requester.instances[scope]) {
            return Requester.instances[scope];
        }

        this.priv = new RequesterPrivates(scope, this);
        Requester.instances[scope] = this;

        return Requester.instances[scope];
    }

    static getConfig(configName) {
        return RequesterPrivates.getConfig(configName);
    }

    post(address, params) {
        return this.priv.post(address, params);
    }

    put(address, params) {
        return this.priv.put(address, params);
    }

    get(address, params) {
        return this.priv.get(address, params);
    }

    getPublicToken() {
        return this.priv.getPublicToken();
    }

}

export default Requester;
