import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import CONSTANTS from '../../services/constants';
import Klevent from '../klevent/Klevent';
import Loader from '../common/Loader';

class Kindwall extends Component {

    loaderTimeout;
    queryReceived;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentWillMount() {
        this.showLoader();

        const {business, wall} = this.props;
        this.props.actions.loadkleventsList({
            businessId: business.pageId,
            wallId: wall
        });
    }

    showLoader() {
        this.setState({loading: true});
        this.loaderTimeout = true;
        this.queryReceived = false;
        let that = this;
        setTimeout(function () {
            that.loaderTimeout = false;
            if (that.queryReceived === true) {
                that.setState({loading: false});
            }
        }, 750);
    }

    componentWillReceiveProps(nextProps) {
        const {klevents} = nextProps;
        if (typeof klevents !== "undefined") {
            this.hideLoader();
        }
    }

    hideLoader() {
        this.queryReceived = true;
        if (!this.loaderTimeout) {
            this.setState({loading: false});
        }
    }

    render() {
        const {klevents, wall} = this.props;
        if (this.state.loading) {
            return (
                <div className="klw-profile--kindwall-item-wrapper" id={"kindwall"}>
                    <Loader parentId={"kindwall"}/>
                </div>
            );
        } else {
            if (klevents.length === 0) {
                return (
                    <div>

                    </div>
                );
            } else {
                return (
                    <div className="klw-profile--kindwall-item-wrapper">
                        {klevents.map((klevent) =>
                            <Klevent key={klevent.id} klevent={klevent} wallId={wall}/>
                        )}
                    </div>
                );
            }
        }
    }
}

Kindwall.propTypes = {
    business: PropTypes.object.isRequired
};
Kindwall.defaultProps = {};

function mapStateToProps(state, ownProps) {
    let wallId = ownProps.business.walls.filter((wall) => {
        if (wall.type === CONSTANTS.PROFILE.WALLS.KINDWALL) {
            return wall;
        }
        return null;
    })[0].wallId;
    return {
        klevents: state.klevents[wallId],
        wall: wallId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Kindwall);