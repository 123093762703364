import Requester from './requester.service';

// api services
const addresses = {
    "createContributionFromDonation": "/campaigns/{campaignUri}/contributions/donation"
};
export const createContributionFromDonation = function (params) {
    let req = new Requester();
    let link = addresses.createContributionFromDonation.replace("{campaignUri}", params.campaignUri);
    return req.put(link, params.donation).then(function (response) {
        response = response.data;
        return {response};
    }, function (error) {
        console.error("CREATE CONTRIBUTION REDUCER FAILURE");
        console.error(error);

        return {error};
    });
};
