import * as actions from '../../actions';
import Klevent from '../../models/Klevent';

export default (state = [], action) => {
    switch (action.type) {
        case actions.KLEVENTS[actions.FAILURE]:
            console.log("KLEVENTS ERROR");
            break;
        case actions.KLEVENTS[actions.SUCCESS]:

            let klevents = [];
            for (let i = 0; i < action.response.body.klEvents.length; i++) {
                let klevent = new Klevent();
                klevent.populate(action.response.body.klEvents[i]);
                klevents.push(klevent);
            }

            let kleventsList = state;
            kleventsList[action.params.wallId] = klevents;
            return Object.assign({}, state, kleventsList);
        default:
            return state;
    }
};
