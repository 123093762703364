import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import {getCharityUrl} from "../../services/utils";
import charityLogo from "../../images/charity-default-logo.png";

class CharitiesListItem extends Component {

    render() {
        const {charity} = this.props;

        let charityInfo = {
            "name": charity.name,
            "url": getCharityUrl(charity.charityPublicId),
            "logo": charity.logo || charityLogo
        };

        return (
            <div className="klw--s-char one-half xs-one-third sm-one-fifth m-one-sixth lg-one-sixth column">
                <div className="klw--s-char--scroll-wrapper">
                    <div className="klw--s-char--charity">
                        <a className="klw--s-char--clogo"
                           href={charityInfo.url} target="_top">
                            <img src={charityInfo.logo} width="125" height="125"
                                 alt={charityInfo.name}
                                 title={charityInfo.name}></img>
                        </a>
                        <div className="klw--s-char--name">{charityInfo.name}</div>
                    </div>
                </div>
            </div>
        );
    }
}

CharitiesListItem.propTypes = {
    charity: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CharitiesListItem);
