//use these functions for cache
export const getConfigSelector = function (state) {
    if (typeof state.initialLoad !== "undefined" && typeof state.initialLoad.config !== "undefined") {
        return state.initialLoad.config;
    } else {
        return null;
    }
};

export const getPublicTokenSelector = function (state) {
    if (typeof state.initialLoad !== "undefined" && typeof state.initialLoad.token !== "undefined") {
        return state.initialLoad.token;
    } else {
        return null;
    }
};

export const getWidgetInfoSelector = function (state) {
    if (typeof state.initialLoad !== "undefined" && typeof state.initialLoad.widget !== "undefined") {
        return state.initialLoad.widget;
    } else {
        return null;
    }
};